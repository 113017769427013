/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('benefit-center', {
    parent: 'root',
    url: '/benefit-center',
    templateUrl: 'apps/benefit-center/templates/acp-benefit-center.ng.html',
    data: {
      permissions: {
        only: 'hasBenefitsCenterEligibility'
      }
    },
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.benefit-center" */ 'apps/benefit-center').then(
          $ocLazyLoad.loadModule
        );
      },
      $benefitTermsUrl: /* @ngInject */ function(acpTermsModel) {
        return acpTermsModel.getBenefitCenterTermsUrl();
      },
      $idMonitoringUrl: /* @ngInject */ function(acpFrontendConfigModel) {
        return acpFrontendConfigModel.get(
          acpFrontendConfigModel.keys.ID_MONITORING_URL
        );
      },
      $benefitsPermissions: /* @ngInject */ function(
        module,
        acpBenefitCenterModel
      ) {
        return acpBenefitCenterModel.getPermissions();
      }
    }
  });
}
export default states;
